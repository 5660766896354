import styled from '@emotion/styled'

/**
 * Typography components
 */
export const HeadingsJumbo = styled.div`
    font-family: ${(props) => props.theme.typography.headingsJumbo.fontFamily};
    font-size: ${(props) => props.theme.typography.headingsJumbo.size};
    font-weight: ${(props) => props.theme.typography.headingsJumbo.weight};
    line-height: ${(props) => props.theme.typography.headingsJumbo.lineheight};
    letter-spacing: ${(props) => props.theme.typography.headingsJumbo.letterSpacing};
`
export const HeadingsH1 = styled.div`
    font-family: ${(props) => props.theme.typography.headingsH1.fontFamily};
    font-size: ${(props) => props.theme.typography.headingsH1.size};
    font-weight: ${(props) => props.theme.typography.headingsH1.weight};
    line-height: ${(props) => props.theme.typography.headingsH1.lineheight};
    letter-spacing: ${(props) => props.theme.typography.headingsH1.letterSpacing};
`
export const HeadingsH2 = styled.div`
    font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
    font-size: ${(props) => props.theme.typography.headingsH2.size};
    font-weight: ${(props) => props.theme.typography.headingsH2.weight};
    line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
    letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
`
export const HeadingsH3 = styled.div`
    font-family: ${(props) => props.theme.typography.headingsH3.fontFamily};
    font-size: ${(props) => props.theme.typography.headingsH3.size};
    font-weight: ${(props) => props.theme.typography.headingsH3.weight};
    line-height: ${(props) => props.theme.typography.headingsH3.lineheight};
    letter-spacing: ${(props) => props.theme.typography.headingsH3.letterSpacing};
`
export const Subheading24 = styled.div`
    font-family: ${(props) => props.theme.typography.subheading24.fontFamily};
    font-size: ${(props) => props.theme.typography.subheading24.size};
    font-weight: ${(props) => props.theme.typography.subheading24.weight};
    line-height: ${(props) => props.theme.typography.subheading24.lineheight};
    letter-spacing: ${(props) => props.theme.typography.subheading24.letterSpacing};
`
export const HeadingsH4 = styled.div`
    font-family: ${(props) => props.theme.typography.headingsH4.fontFamily};
    font-size: ${(props) => props.theme.typography.headingsH4.size};
    font-weight: ${(props) => props.theme.typography.headingsH4.weight};
    line-height: ${(props) => props.theme.typography.headingsH4.lineheight};
    letter-spacing: ${(props) => props.theme.typography.headingsH4.letterSpacing};
`
export const Subheading20 = styled.div`
    font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
    font-size: ${(props) => props.theme.typography.subheading20.size};
    font-weight: ${(props) => props.theme.typography.subheading20.weight};
    line-height: ${(props) => props.theme.typography.subheading20.lineheight};
    letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
`
export const Paragraph18 = styled.div`
    font-family: ${(props) => props.theme.typography.paragraph18.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    font-weight: ${(props) => props.theme.typography.paragraph18.weight};
    line-height: ${(props) => props.theme.typography.paragraph18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph18.letterSpacing};
`
export const AllCaps16 = styled.div`
    font-family: ${(props) => props.theme.typography.allCaps16.fontFamily};
    font-size: ${(props) => props.theme.typography.allCaps16.size};
    font-weight: ${(props) => props.theme.typography.allCaps16.weight};
    line-height: ${(props) => props.theme.typography.allCaps16.lineheight};
    letter-spacing: ${(props) => props.theme.typography.allCaps16.letterSpacing};
    text-transform: uppercase;
`
export const Subheading16 = styled.div`
    font-family: ${(props) => props.theme.typography.subheading16.fontFamily};
    font-size: ${(props) => props.theme.typography.subheading16.size};
    font-weight: ${(props) => props.theme.typography.subheading16.weight};
    line-height: ${(props) => props.theme.typography.subheading16.lineheight};
    letter-spacing: ${(props) => props.theme.typography.subheading16.letterSpacing};
`
export const Paragraph16 = styled.div`
    font-family: ${(props) => props.theme.typography.paragraph16.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph16.size};
    font-weight: ${(props) => props.theme.typography.paragraph16.weight};
    line-height: ${(props) => props.theme.typography.paragraph16.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph16.letterSpacing};
`
export const AllCaps14 = styled.div`
    font-family: ${(props) => props.theme.typography.allCaps14.fontFamily};
    font-size: ${(props) => props.theme.typography.allCaps14.size};
    font-weight: ${(props) => props.theme.typography.allCaps14.weight};
    line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
    letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    text-transform: uppercase;
`
export const Paragraph14 = styled.div`
    font-family: ${(props) => props.theme.typography.paragraph14.fontFamily};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    font-weight: ${(props) => props.theme.typography.paragraph14.weight};
    line-height: ${(props) => props.theme.typography.paragraph14.lineheight};
    letter-spacing: ${(props) => props.theme.typography.paragraph14.letterSpacing};
`
export const AllCaps12 = styled.div`
    font-family: ${(props) => props.theme.typography.allCaps12.fontFamily};
    font-size: ${(props) => props.theme.typography.allCaps12.size};
    font-weight: ${(props) => props.theme.typography.allCaps12.weight};
    line-height: ${(props) => props.theme.typography.allCaps12.lineheight};
    letter-spacing: ${(props) => props.theme.typography.allCaps12.letterSpacing};
    text-transform: uppercase;
`
