/* eslint-disable no-console */
import countryData from './countries.json'

interface ResultProps {
    version?: number
    url?: string
    source_latitude?: number
    source_longitude?: number
    dest_latitude?: number
    dest_longitude?: number

    numberOfHops?: number
    monthlyVisitors?: number
    greenHosting?: boolean
    renewableEnergyPercent: number
    save?: boolean
    carbonProducedAnnually?: number
    energyConsumedAnnually?: number
    payloadSize: number
    distanceInMiles: number
}
interface ReportProps {
    version?: number
    url?: string
    source?: string
    destination?: string
    numberOfHops?: number
    monthlyVisitors?: number
    greenHosting?: boolean
    save?: boolean
}

async function calculateCo2(report: ReportProps) {
    if (!report.url) {
        throw new Error(`URL is required`)
    }
    let url = report.url
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`
    }

    const response = await fetch('https://jteu06e99a.execute-api.us-east-1.amazonaws.com/prod', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
    })

    if (!response.ok) {
        throw new Error(`Error fetching size from Lambda. Status: ${response.status}`)
    }

    const data = await response.json()
    const size = data.size

    let userLocation = await getUserLatLong()
    if (userLocation === null) {
        console.warn('User location not found. Using default values (London).')
        userLocation = {
            latitude: 51.5074,
            longitude: -0.1278,
        }
    }

    const energy = getEnergyConsumptionPerVisit('desktop', size) * (report.monthlyVisitors || 10000) * 12
    const carbon = getCarbonEmissionsPerVisit(energy)
    let distance = getDistance(userLocation['latitude'], userLocation['longitude'], 40.7128, -74.006)
    distance = Math.round(distance)

    const co2EmissionResult: ResultProps = {
        version: 2.0,
        url: url,
        source_latitude: 45.85,
        source_longitude: -119.5667,
        dest_latitude: userLocation['latitude'],
        dest_longitude: userLocation['longitude'],
        numberOfHops: 10,
        monthlyVisitors: report.monthlyVisitors || 10000,
        greenHosting: report.greenHosting || false,
        renewableEnergyPercent: 75,
        carbonProducedAnnually: carbon,
        energyConsumedAnnually: energy,
        payloadSize: size,
        distanceInMiles: distance,
    }

    // Simulate processing delay
    await new Promise((resolve) => setTimeout(resolve, 1000))

    return co2EmissionResult
}

function getEnergyConsumptionPerVisit(deviceType: string, dataSize: number) {
    // get energy consumption per visit
    const WANIntentsity = 0.000000000152 // in kwh/bye, from coromara21 report
    // const avgNumHops = 13 // on average, 13 number of hops will be traveled.
    const dataCenterIntensity = 7.2e-11 // in kwh/byte
    let devIntensity = 3.2e-4

    if (deviceType === 'mobile') {
        devIntensity = 1.1e-4 // in kwh/byte
    }

    const dataCenterEnergy = dataSize * dataCenterIntensity

    const totalEnergy = dataCenterEnergy + dataSize * WANIntentsity + devIntensity
    return totalEnergy
}

function getCarbonEmissionsPerVisit(energyConsumed: number) {
    // get location carbon factor
    const carbonFactor = 380.0 // in g/kwh is US average
    return energyConsumed * carbonFactor
}

// async function getLocationFromIp(ip: string) {
//     const ipNumber = ipToInt(ip).toInt(); // Convert IP to integer

//     for (const row of ipGeolocationData) {
//         const startIp = ipToInt(row.start_ip).toInt();
//         const endIp = ipToInt(row.end_ip).toInt();

//         if (ipNumber >= startIp && ipNumber <= endIp) {
//             return {
//                 country: row.country,
//                 region: row.region,
//                 city: row.city,
//             };
//         }
//     }

//     return null;
// }

async function fetchUserCountry() {
    try {
        const userCountryResponse = await fetch('https://country.tryshift.com')
        if (userCountryResponse.ok) {
            const userCountry = await userCountryResponse.text()
            return userCountry
        } else {
            console.error(`Failed to fetch country information: HTTP status ${userCountryResponse.status}`)
            return null
        }
    } catch (error) {
        console.error('Failed to fetch country information:', error)
        return null
    }
}

const countryMap = new Map(countryData.map((c) => [c['Alpha-2 code'], c]))

function getLatLongByCountryCode(countryCode: string) {
    const country = countryMap.get(countryCode)
    if (country) {
        return {
            latitude: country['Latitude (average)'],
            longitude: country['Longitude (average)'],
        }
    } else {
        console.error(`Country code ${countryCode} not found in data`)
        return null
    }
}

// Main function to combine the workflow
async function getUserLatLong() {
    const countryCode = await fetchUserCountry()
    if (countryCode) {
        const latLong = getLatLongByCountryCode(countryCode)
        if (latLong) {
            return latLong
        }
    }
    return null
}

function getDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'km' | 'miles' = 'km'): number {
    const toRadians = (degrees: number) => (degrees * Math.PI) / 180

    const R = unit === 'km' ? 6371 : 3958.8 // Earth's radius in kilometers or miles
    const dLat = toRadians(lat2 - lat1)
    const dLon = toRadians(lon2 - lon1)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c // Distance in the specified unit
}

export default calculateCo2
