import styled from '@emotion/styled'

/*
A Run button for the calculator
*/
export const RunButton = styled.button`
    padding: ${(props) => props.theme.spacing.normal};
    margin: ${(props) => props.theme.spacing.normal};
    width: ${(props) => props.theme.width.button};
    font-size: ${(props) => props.theme.typography.subheading16.size};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    border-radius: ${(props) => props.theme.borderRadius.button};
    color: ${(props) => props.theme.color.text};
    background-color: ${(props) => props.theme.color.background};
    cursor: ${(props) => props.theme.cursor.button};
    &:hover {
        background-color: ${(props) => props.theme.cursor.button};
    }
`

// Small arbutus button component
export const SmallArbutusButton = styled.button`
    width: ${(props) => props.theme.button.width.small};
    height: ${(props) => props.theme.button.height.small};
    display: ${(props) => props.theme.button.display};
    flex-direction: ${(props) => props.theme.button.flex_direction};
    justify-content: ${(props) => props.theme.button.justify_content};
    align-items: ${(props) => props.theme.button.align_items};
    gap: ${(props) => props.theme.button.gap};
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    text-transform: uppercase;
    font-size: ${(props) => props.theme.typography.button.allCaps14.size};
    font-weight: ${(props) => props.theme.typography.button.allCaps14.weight};
    line-height: ${(props) => props.theme.typography.button.allCaps14.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps14.letterSpacing};
    padding: ${(props) => props.theme.button.padding.small};
    border-radius: ${(props) => props.theme.button.border_radius.small.default};
    color: ${(props) => props.theme.primaryColor.white};
    border: ${(props) => props.theme.primaryColor.arbutus[50]};
    background: ${(props) => props.theme.primaryColor.arbutus[50]};
    &:hover {
        background-color: ${(props) => props.theme.primaryColor.arbutus[75]};
    }
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.small.active};
        border: 2px solid ${(props) => props.theme.primaryColor.arbutus[25]};
        background-color: ${(props) => props.theme.primaryColor.arbutus[75]};
    }
    &:disabled {
        color: ${(props) => props.theme.primaryColor.arbutus[25]};
        border-radius: ${(props) => props.theme.button.border_radius.small.active};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
`
export const BigArbutusButton = styled(SmallArbutusButton)`
    width: ${(props) => props.theme.button.width.large};
    height: ${(props) => props.theme.button.height.large};
    padding: ${(props) => props.theme.button.padding.large};
    font-size: ${(props) => props.theme.typography.button.allCaps18.size};
    line-height: ${(props) => props.theme.typography.button.allCaps18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps18.letterSpacing};
    border-radius: ${(props) => props.theme.button.border_radius.large.default};
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
    &:disabled {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
`

export const SmallWhiteButton = styled(SmallArbutusButton)`
    color: ${(props) => props.theme.primaryColor.black[100]};
    border: ${(props) => props.theme.primaryColor.white};
    background-color: ${(props) => props.theme.primaryColor.white};
    cursor: ${(props) => props.theme.cursor.button};
    &:hover {
        flex-grow: ${(props) => props.theme.button.flex_grow};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
    &:active {
        border: 2px solid ${(props) => props.theme.primaryColor.lake[25]};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
    &:disabled {
        color: ${(props) => props.theme.primaryColor.black[25]};
        border-radius: ${(props) => props.theme.button.border_radius.small.disabled};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
`

export const BigWhiteButton = styled(SmallWhiteButton)`
    width: ${(props) => props.theme.button.width.large};
    height: ${(props) => props.theme.button.height.large};
    padding: ${(props) => props.theme.button.padding.large};
    font-size: ${(props) => props.theme.typography.button.allCaps18.size};
    line-height: ${(props) => props.theme.typography.button.allCaps18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps18.letterSpacing};
    border-radius: ${(props) => props.theme.button.border_radius.large.default};
    cursor: ${(props) => props.theme.cursor.button};
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
    &:disabled {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
`

export const SmallLakeButton = styled(SmallArbutusButton)`
    border: ${(props) => props.theme.primaryColor.lake[75]};
    background: ${(props) => props.theme.primaryColor.lake[75]};
    &:hover {
        background-color: ${(props) => props.theme.primaryColor.lake[50]};
    }
    &:active {
        border: 2px solid ${(props) => props.theme.primaryColor.lake[75]};
        background-color: ${(props) => props.theme.primaryColor.lake[50]};
    }
    &:disabled {
        color: ${(props) => props.theme.primaryColor.lake[25]};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
`

export const BigLakeButton = styled(SmallLakeButton)`
    width: ${(props) => props.theme.button.width.large};
    height: ${(props) => props.theme.button.height.large};
    padding: ${(props) => props.theme.button.padding.large};
    font-size: ${(props) => props.theme.typography.button.allCaps18.size};
    line-height: ${(props) => props.theme.typography.button.allCaps18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps18.letterSpacing};
    border-radius: ${(props) => props.theme.button.border_radius.large.default};
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
    &:disabled {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
`

export const SmallPineButton = styled(SmallArbutusButton)`
    border: ${(props) => props.theme.primaryColor.pine[75]};
    background-color: ${(props) => props.theme.primaryColor.pine[75]};
    &:hover {
        background-color: ${(props) => props.theme.primaryColor.pine[50]};
    }
    &:active {
        border: 2px solid ${(props) => props.theme.primaryColor.pine[75]};
        background-color: ${(props) => props.theme.primaryColor.pine[50]};
    }
    &:disabled {
        color: ${(props) => props.theme.primaryColor.pine[25]};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
`

export const BigPineButton = styled(SmallPineButton)`
    width: ${(props) => props.theme.button.width.large};
    height: ${(props) => props.theme.button.height.large};
    padding: ${(props) => props.theme.button.padding.large};
    font-size: ${(props) => props.theme.typography.button.allCaps18.size};
    line-height: ${(props) => props.theme.typography.button.allCaps18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps18.letterSpacing};
    border-radius: ${(props) => props.theme.button.border_radius.large.default};
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
    &:disabled {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
`

export const SmallBlackButton = styled(SmallArbutusButton)`
    border: ${(props) => props.theme.primaryColor.black[100]};
    background-color: ${(props) => props.theme.primaryColor.black[100]};
    cursor: ${(props) => props.theme.cursor.button};
    &:hover {
        background-color: ${(props) => props.theme.primaryColor.black[50]};
    }
    &:active {
        border: 2px solid ${(props) => props.theme.primaryColor.black[75]};
        background-color: ${(props) => props.theme.primaryColor.black[50]};
    }
    &:disabled {
        color: ${(props) => props.theme.primaryColor.black[25]};
        background-color: ${(props) => props.theme.primaryColor.cloud[20]};
    }
`

export const BigBlackButton = styled(SmallBlackButton)`
    width: ${(props) => props.theme.button.width.large};
    height: ${(props) => props.theme.button.height.large};
    padding: ${(props) => props.theme.button.padding.large};
    font-size: ${(props) => props.theme.typography.button.allCaps18.size};
    line-height: ${(props) => props.theme.typography.button.allCaps18.lineheight};
    letter-spacing: ${(props) => props.theme.typography.button.allCaps18.letterSpacing};
    border-radius: ${(props) => props.theme.button.border_radius.large.default};
    cursor: ${(props) => props.theme.cursor.button};
    &:active {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
    &:disabled {
        border-radius: ${(props) => props.theme.button.border_radius.large.active};
    }
`
