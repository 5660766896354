import React from 'react'
import styled from '@emotion/styled'
import { DefaultInputBox, DefaultTextarea, ErrorText, InputWrapper } from '../components/atoms/formInput'
import { DefaultLabel, RequiredLabel } from 'components/atoms/label'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SmallBlackButton } from 'components/atoms/button'
import { AllCaps16, HeadingsH1, Paragraph18 } from 'styles/typography'
import { PlainLink } from 'components/atoms/link'
import config from '../config.json'

// styling for StyledDiv
const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 744px;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
`
// Span that contains text after form is submitted
const SubmittedSpan = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 416px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
        margin-left: ${(props) => props.theme.spacing.s};
        margin-right: ${(props) => props.theme.spacing.s};
    }
`
// Styled paragraph for text displayed when the form is submitted
const SubmittedParagraph = styled(Paragraph18)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    text-align: center;
    margin-top: ${(props) => props.theme.spacing.m};
`
// Container for entire contact form
const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${(props) => props.theme.spacing.xl};
    padding-bottom: ${(props) => props.theme.spacing.xl};
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        flex-direction: column;
        align-items: center;
        padding-top: ${(props) => props.theme.spacing.xxl.default};
        padding-bottom: ${(props) => props.theme.spacing.xxl.default};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding-left: ${(props) => props.theme.spacing.s};
        padding-right: ${(props) => props.theme.spacing.s};
    }
`
// container for the contact text
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 528px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        align-items: center;
        margin-bottom: ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`
// styling for the FormWrapper
const FormWrapper = styled.form`
    display: flex
    flex-direction: column;
    width: 528px;
    justify-content: center;
    margin-left: ${(props) => props.theme.spacing.m};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-left: 0px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        width: 100%;
    }
`
// Styling for button wrapper
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
// Styling for the 'Contact Us' title
const ContactTitle = styled(AllCaps16)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    margin-bottom: ${(props) => props.theme.spacing.m};
`
// Styling for the ContactParagraph
const ContactParagraph = styled(Paragraph18)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    margin-top: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
    }
`
// Media query styling for ContactHeading
const ContactHeading = styled(HeadingsH1)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        text-align: center;
        font-family: ${(props) => props.theme.typography.headingsH2.fontFamily};
        font-size: ${(props) => props.theme.typography.headingsH2.size};
        font-weight: ${(props) => props.theme.typography.headingsH2.weight};
        line-height: ${(props) => props.theme.typography.headingsH2.lineheight};
        letter-spacing: ${(props) => props.theme.typography.headingsH2.letterSpacing};
    }
`

const ContactForm = () => {
    let nameError = false
    let emailError = false
    let messageError = false
    const [isSubmitted, setIsSubmitted] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [inputSuccess, setInputSuccess] = React.useState('')

    // Input validation object to check user inputs against
    const InputValidation = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        company: Yup.string(),
        message: Yup.string().required(),
    })

    // An object with methods to register InputValidation, handle submissions, and provide error information
    const {
        register, // method to register InputValidation
        handleSubmit, // method to handle submission
        formState: { errors }, // contains error information
        getValues,
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(InputValidation),
    })

    // Takes a data object as a parameter and sends a POST request containing that data to the API
    const submitData = (data: unknown) => {
        try {
            fetch(`${config.apiUrl}/mail`, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
        } catch (error) {
            console.error(error)
        }
        setIsSubmitted(true)
    }

    // If submit button is clicked set success to 'success'
    const submitAttempt = () => {
        setSuccess('success')
        if (getValues('company') != '') {
            setInputSuccess('success')
        }
    }

    // Sets nameError, emailError, and messageError to true respectively if there is a name, email, or message error thrown
    if (errors.name) {
        nameError = true
    }
    if (errors.email) {
        emailError = true
    }
    if (errors.message) {
        messageError = true
    }

    // If isSubmitted is true render the thank you page
    if (isSubmitted == true) {
        return (
            <StyledDiv>
                <SubmittedSpan>
                    <ContactHeading>Thank you!</ContactHeading>
                    <SubmittedParagraph>
                        We'll reach out to you shortly. In the meantime, feel free to learn more{' '}
                        <PlainLink to='.contact'>about us.</PlainLink>
                    </SubmittedParagraph>
                </SubmittedSpan>
            </StyledDiv>
        )
    }

    // Render the contact form
    return (
        <ContactContainer>
            <TextContainer>
                <ContactTitle>Contact Us</ContactTitle>
                <ContactHeading>Join the companies tackling their digital emissions.</ContactHeading>
                <ContactParagraph>
                    Connect with us to learn more about getting precise digital carbon footprint data through our SDK or
                    audit.
                </ContactParagraph>
            </TextContainer>

            <FormWrapper onSubmit={handleSubmit(submitData)}>
                <InputWrapper>
                    <RequiredLabel>Full Name</RequiredLabel>
                    <DefaultInputBox
                        {...register('name')}
                        className={nameError ? 'error' : success}
                        placeholder={'John Smith'}
                    />
                    <ErrorText stateType={nameError ? 'error' : success} message={'Please input your full name'} />
                </InputWrapper>

                <InputWrapper>
                    <RequiredLabel>Email Address</RequiredLabel>
                    <DefaultInputBox
                        {...register('email')}
                        className={emailError ? 'error' : success}
                        placeholder={'john@smith.com'}
                    />
                    <ErrorText stateType={emailError ? 'error' : success} message={'Please input a valid email'} />
                </InputWrapper>

                <InputWrapper>
                    <DefaultLabel>Company</DefaultLabel>
                    <DefaultInputBox
                        className={inputSuccess}
                        {...register('company', {
                            onChange: (val) => {
                                if (success === 'success') {
                                    setInputSuccess('success')
                                }
                                if (val.target.value === '') {
                                    setInputSuccess('')
                                }
                            },
                        })}
                        placeholder={'Acme Corporation'}
                    />
                    <ErrorText />
                </InputWrapper>

                <InputWrapper>
                    <RequiredLabel>Message</RequiredLabel>
                    <DefaultTextarea
                        {...register('message')}
                        className={messageError ? 'error' : success}
                        placeholder={'Any questions or comments?'}
                    />
                    <ErrorText stateType={messageError ? 'error' : success} message={'Please input your message'} />
                </InputWrapper>

                <ButtonWrapper>
                    <SmallBlackButton onClick={submitAttempt}>Submit</SmallBlackButton>
                </ButtonWrapper>
            </FormWrapper>
        </ContactContainer>
    )
}

export default ContactForm
