import { Theme } from '@emotion/react'
/**
 * Implement the theme interface
 */
const defaultTheme: Theme = {
    home: {
        height: {
            desktop: '640px',
            tablet: '424px',
            mobile: '584px',
            image: '480px',
            tabletButton: '40px',
        },
        width: {
            image: '240px',
            desktopContainer: '640px',
            mobileContainer: '312px',
            desktopButton: '332px',
            tabletButton: '244px',
        },
        margin: {
            rightImage: {
                tabletTop: '-102px',
                mobileTop: '-30px',
            },
            leftImage: {
                desktopTop: '160px',
                tabletTop: '52px',
                mobileTop: '130px',
            },
        },
    },
    corporate: {
        height: {
            backgroundDesktop: '1116px',
            backgroundTablet: '1508px',
            backgroundMobile: '1594.8px',
            sdkDesktop: '576px',
            sdkMobile: '342.8px',
            button: '40px',
            scopeContainerDesktop: '104px',
            scopeContainerTablet: '104px',
            scopeContainerMobile: '152px',
        },
        width: {
            sdkDesktop: '528px',
            sdkMobile: '321px',
            connectTextBox: '496px',
            button: '168px',
            scopeContainerDesktop: '492px',
            scopeContainerTablet: '524px',
            scopeContainerMobile: '304px',
        },
        border: {
            scope: '4px',
        },
    },
    height: {
        footer: {
            desktop: '72px',
            tablet: '248px',
            mobile: '248px',
        },
        banner: {
            desktop: '24px',
            tablet: '24px',
            mobile: '88px',
        },
        tooltip: '16px',
    },
    fonts: {
        manropeRegular: 'Manrope-Regular',
        manropeSemiBold: 'Manrope-SemiBold',
        tinosRegular: 'Tinos-Regular',
    },
    breakpoints: {
        desktop: '1440px',
        tablet: '768px',
        mobile: '581px',
        mobileMin: '320px',
    },
    typography: {
        headingsJumbo: {
            fontFamily: 'Tinos-Regular',
            size: '80px',
            weight: 'normal',
            lineheight: '96px',
            letterSpacing: 'normal',
        },
        headingsH1: {
            fontFamily: 'Tinos-Regular',
            size: '56px',
            weight: 'normal',
            lineheight: '64px',
            letterSpacing: 'normal',
        },
        headingsH2: {
            fontFamily: 'Tinos-Regular',
            size: '40px',
            weight: 'normal',
            lineheight: '48px',
            letterSpacing: 'normal',
        },
        headingsH3: {
            fontFamily: 'Tinos-Regular',
            size: '32px',
            weight: 'normal',
            lineheight: '40px',
            letterSpacing: 'normal',
        },
        subheading24: {
            fontFamily: 'Manrope-SemiBold',
            size: '24px',
            weight: '600',
            lineheight: '40px',
            letterSpacing: 'normal',
        },
        headingsH4: {
            fontFamily: 'Tinos-Regular',
            size: '24px',
            weight: 'normal',
            lineheight: '32px',
            letterSpacing: 'normal',
        },
        subheading20: {
            fontFamily: 'Manrope-SemiBold',
            size: '20px',
            weight: '600',
            lineheight: '32px',
            letterSpacing: 'normal',
        },
        subheading16: {
            fontFamily: 'Manrope-SemiBold',
            size: '16px',
            weight: '600',
            lineheight: '32px',
            letterSpacing: 'normal',
        },
        paragraph18: {
            fontFamily: 'Manrope-Regular',
            size: '18px',
            weight: 'normal',
            lineheight: '32px',
            letterSpacing: 'normal',
        },
        allCaps16: {
            fontFamily: 'Manrope-SemiBold',
            size: '16px',
            weight: '600',
            lineheight: '24px',
            letterSpacing: '1.28px',
        },
        paragraph16: {
            fontFamily: 'Manrope-Regular',
            size: '16px',
            weight: 'normal',
            lineheight: '24px',
            letterSpacing: 'normal',
        },
        allCaps14: {
            fontFamily: 'Manrope-SemiBold',
            size: '14px',
            weight: '600',
            lineheight: '24px',
            letterSpacing: '1.12px',
        },
        paragraph14: {
            fontFamily: 'Manrope-Regular',
            size: '14px',
            weight: 'normal',
            lineheight: '24px',
            letterSpacing: 'normal',
        },
        allCaps12: {
            fontFamily: 'Manrope-SemiBold',
            size: '12px',
            weight: '600',
            lineheight: '16px',
            letterSpacing: '0.96px',
        },
        button: {
            allCaps14: {
                size: '14px',
                weight: '600',
                lineheight: '1.71',
                letterSpacing: '1.12px',
            },
            allCaps18: {
                size: '18px',
                weight: '600',
                lineheight: '1.78',
                letterSpacing: '1.44px',
            },
        },
        formLabel: {
            fontFamily: 'Manrope-Regular',
            size: '24px',
            lineheight: '32px',
        },
    },
    tooltip: {
        typography: {
            lineheight: '1.14',
            weight: '600',
        },
        style: {
            radius: '8px',
        },
        width: {
            wrapper: '240px',
            bodyside: '196px',
            bodytopdown: '208px',
        },
        arrow: {
            side: '8px',
            height: '12px',
        },
    },
    spacing: {
        narrow: '6px',
        normal: '12px',
        wide: '16px',
        xxs: '8px',
        xs: '16px',
        s: '24px',
        m: '32px',
        l: '48px',
        xl: '96px',
        xxl: {
            default: '64px',
            2: '128px',
        },
        padding: {
            vertical: '40px',
        },
        gap: '32px',
    },
    width: {
        button: '150px',
        picture: '500px',
        logo: '200px',
        tooltip: '208px',
    },
    button: {
        width: {
            small: '111px',
            large: '161px',
        },
        height: {
            small: '40px',
            large: '64px',
        },
        padding: {
            small: '8px 24px',
            large: '16px 40px',
        },
        display: 'flex',
        flex_grow: '0px',
        flex_direction: 'row',
        justify_content: 'center',
        align_items: 'center',
        gap: '8px',
        border_radius: {
            small: {
                default: '180px',
                active: '20px',
                disabled: '20px',
            },
            large: {
                default: '180px',
                active: '32px',
                disabled: '32px',
            },
        },
    },
    fontWeight: {
        light: '300',
        normal: '500',
        bold: '700',
    },
    borderRadius: {
        button: '16px',
        picture: '10px',
    },
    color: {
        header: '#21618C',
        text: '#FFFFFF',
        background: '#C0392B',
        hover: '#4caf50',
    },
    primaryColor: {
        white: '#ffffff',
        cloud: {
            10: '#f5f6f4',
            20: '#ebede9',
        },
        pine: {
            15: '#d0e3d1',
            25: '#a7c0a8',
            50: '#799c7a',
            75: '#507551',
        },
        arbutus: {
            15: '#eed1a8',
            25: '#e7bc7e',
            50: '#cb974b',
            75: '#9e712e',
        },
        lake: {
            10: '#e7ecf3',
            15: '#c5d2e8',
            25: '#a8bbdc',
            50: '#7d99ca',
            75: '#5177b8',
        },
        black: {
            25: '#c5c5c5',
            50: '#888',
            75: '#444',
            100: '#000',
        },
    },
    cursor: {
        button: 'pointer',
        picture: 'zoom-in',
    },
    input: {
        width: '400px',
        border: '2px',
        paddingLarge: '12px',
    },
    form: {
        padding: '24px',
        borderRadius: '4px',
    },
}

export { defaultTheme }
