// Home Page
import { ContentComponent } from 'components/home/homeContent'
import About from './About'
import Corporate from './Corporate'
import HowItWorks from './HowItWorks'

export default function Home() {
    return (
        <div>
            <ContentComponent />
            <Corporate />
            <About />
            <HowItWorks />
        </div>
    )
}
