import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingAnimation from 'components/animation/loadingAnimation'
import { SmallBlackButton } from 'components/atoms/button'
import { Card } from 'components/atoms/card'
import FormDropdown from 'components/atoms/formDropdown'
import { DefaultInputBox, ErrorText, InputWrapper } from 'components/atoms/formInput'
import { DefaultLabel } from 'components/atoms/label'
import { RadioButton } from 'components/atoms/radioButton'
import RightBar from 'components/results/rightBar'
import { color } from 'd3-color'
import { interpolateRgb } from 'd3-interpolate'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import LiquidFillGauge from 'react-liquid-gauge'
import { useNavigate } from 'react-router-dom'
import { AllCaps16, Paragraph16, Subheading20, Subheading24 } from 'styles/typography'
import { addDelay } from 'utils/addDelay'
import * as Yup from 'yup'
import copyLinkIcon from '../assets/icons/copy-link-gray.svg'
import twitterIcon from '../assets/icons/twitter-gray.svg'
import config from '../config.json'
// The Leftbar/Dropdown form of the results section, i.e. the "How did we calculate this?" part

// Container for the leftbar that will disappear when the page is in tablet or mobile screen size
const LeftBarContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 100%;
    width: 30%;
    max-width: 416px;
    box-sizing: border-box;
    padding: 40px ${(props) => props.theme.spacing.m} 0px;
    gap: 31px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        display: none;
    }
`

// Container for the dropdown form that will appear when the page is in tablet or mobile screen size
const DropdownFormContainer = styled.div`
    width: 100vw;
    @media (min-width: 769px) {
        display: none;
    }
`

// Container for all the answers
const AnswerContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`
// Container for each answer/subsection
const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: ${(props) => props.theme.primaryColor.white};
    padding: ${(props) => props.theme.spacing.m} 0px 31px;
    gap: ${(props) => props.theme.spacing.s};
    border-top: 1px solid ${(props) => props.theme.primaryColor.black[25]};
`
const TextSubContainer = styled(SubContainer)`
    padding-bottom: 0px;
`

// Wrapper for the subheading of each answer
const SubheadingWrapper = styled(Subheading20)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${(props) => props.theme.spacing.xxs};
    color: ${(props) => props.theme.primaryColor.black[100]};
`

// Wrapper for the sentence of each answer
const SentenceWrapper = styled(Paragraph16)`
    color: ${(props) => props.theme.primaryColor.black[75]};
    margin-top: -16px;
`

// Wrapper for the input label and input box
const InputBoxWrapper = styled(DefaultInputBox)`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        margin-right: 100px;
        width: 100%;
    }
`

// Wrapper for the radio button and label
const RadioWrapper = styled(Subheading20)`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing.xxs};
    label {
        color: ${(props) => props.theme.primaryColor.black[100]};
        font-family: ${(props) => props.theme.typography.paragraph16.fontFamily};
        font-size: ${(props) => props.theme.typography.paragraph16.size};
        font-weight: ${(props) => props.theme.typography.paragraph16.weight};
        line-height: ${(props) => props.theme.typography.paragraph16.lineheight};
        letter-spacing: ${(props) => props.theme.typography.paragraph16.letterSpacing};
        margin-right: 8px;
    }
`

// Container for the whole section
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.primaryColor.cloud[10]};
    gap: ${(props) => props.theme.spacing.m};
    padding: ${(props) => props.theme.spacing.xl} ${(props) => props.theme.spacing.xxl.default};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${(props) => props.theme.spacing.s};
        padding: 0px ${(props) => props.theme.spacing.l} ${(props) => props.theme.spacing.l};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0px ${(props) => props.theme.spacing.s} ${(props) => props.theme.spacing.l};
    }
`
// Wrapper for the website info card
const WebCard = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    gap: ${(props) => props.theme.spacing.s};
    padding: ${(props) => props.theme.spacing.m};
`

// Wrapper for the heading part of the website info card
const HeadingWrapper = styled(AllCaps16)`
    color: ${(props) => props.theme.primaryColor.lake[75]};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.allCaps14.fontFamily};
        font-size: ${(props) => props.theme.typography.allCaps14.size};
        font-weight: ${(props) => props.theme.typography.allCaps14.weight};
        line-height: ${(props) => props.theme.typography.allCaps14.lineheight};
        letter-spacing: ${(props) => props.theme.typography.allCaps14.letterSpacing};
    }
`

// Wrapper for the website address part of the website info card
const AddressWrapper = styled.div`
    color: ${(props) => props.theme.primaryColor.black[100]};
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
    overflow-wrap: break-word;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-family: ${(props) => props.theme.typography.subheading20.fontFamily};
        font-size: ${(props) => props.theme.typography.subheading20.size};
        font-weight: ${(props) => props.theme.typography.subheading20.weight};
        line-height: ${(props) => props.theme.typography.subheading20.lineheight};
        letter-spacing: ${(props) => props.theme.typography.subheading20.letterSpacing};
    }
`

// Wrapper for the SNS logos part of the website info card
const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 68px;
    height: 24px;
    align-items: stretch;
    img {
        position: relative;
        display: inline-block;
        &:hover {
            cursor: ${(props) => props.theme.cursor.button};
        }
    }
`
// Container for the the whole right section
const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    max-width: 864px;
    height: 100%;
    gap: ${(props) => props.theme.spacing.m};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        gap: ${(props) => props.theme.spacing.s};
        width: 100%;
    }
`
// Styling for recalculate button
const StyledButton = styled(SmallBlackButton)`
    width: auto;
`
// Styling for the "Calculate again" text
const Recalc = styled.a`
    text-decoration: underline;
    cursor: ${(props) => props.theme.cursor.button};
    &:hover {
        color: ${(props) => props.theme.primaryColor.lake[75]};
    }
`

const ToolTipText = styled.div`
    margin: ${(props) => props.theme.spacing.xxs} ${(props) => props.theme.spacing.xs};
    font-family: ${(props) => props.theme.fonts.manropeSemiBold};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    line-height: ${(props) => props.theme.tooltip.typography.lineheight};
    color: ${(props) => props.theme.primaryColor.white};
`

const StyledToolTipContainer = styled.span`
    position: absolute;
    width: 150px;
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.primaryColor.black[100]};
    border-radius: ${(props) => props.theme.tooltip.style.radius};
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    &::after {
        position: absolute;
        content: '';
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 12px solid ${(props) => props.theme.primaryColor.black[100]};
        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
        top: -12px;
    }
`

const ToolTipItem = styled.div`
    position: relative;
    display: inline-block;
`

// All the answers
export function Result(props: any) {
    const [spinner, setSpinner] = useState(false)
    let visitorError
    let energyError

    const scrollRef = useRef<any>()
    // State that gets and sets the report data
    const [reportData, setReportData] = useState({ ...props.data, datetime: '' })
    // State to show the results
    const [isSubmitted, setIsSubmitted] = useState(props.isSubmitted)
    // State to update datetime when "Calculate again" is clicked
    const [reportDate, setReportDate] = useState('')
    // Ref to keep track of rerenders
    const dateUpdate = useRef(0)

    const recalculateValidation = Yup.object().shape({
        visitors: Yup.string()
            .matches(/^[0-9]*$/, 'Please input a positive number')
            .transform((value) => (value === '' ? undefined : value.toString().replace(/,/g, ''))),
        // renewableEnergy: Yup.string().matches(/^[0-9]*$/, 'Please input a positive number'), TODO: Add back line
    })

    const {
        register, // method to register user input
        handleSubmit, // method to handle submission of user input
        formState: { errors }, // contains error information
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(recalculateValidation),
    })

    const radius = 200
    const interpolate = interpolateRgb('#799c7a', '#799c7a')
    const fillColor = interpolate(50 / 100)
    const gradientStops = [
        {
            key: '0%',
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: '0%',
        },
        {
            key: '50%',
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: '50%',
        },
        {
            key: '100%',
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: '100%',
        },
    ]

    const RetreiveData = (data: any) => {
        if (data.visitors === '') {
            data.visitors = props.data.monthlyVisitors
        }
        if (data.renewableEnergy === '') {
            data.renewableEnergy = props.data.renewableEnergyPercent
        }

        if (data.greenHosting === null) {
            data.greenHosting = props.data.greenHosting
        } else if (data.greenHosting === 'true') {
            data.greenHosting = true
        } else {
            data.greenHosting = false
        }

        const report = {
            url: props.data.url,
            version: 2.0, // TO-DO: Extract version into a config file
            numberOfHops: 12, //TO-DO: Get number of hops between source and destination
            monthlyVisitors: data.visitors,
            greenHosting: data.greenHosting,
            save: false,
        }

        results(report)
    }

    // Forces a new report to be calculated for the url
    const forceRecalculate = () => {
        const input = {
            ...props.data,
            forceNew: true,
            save: true,
        }
        results(input)
    }

    // Forces a new report to be calculated for the url
    const modifyUrl = (url: string): string => url.replaceAll('https://', '').replaceAll('http://', '')
    const removeWWW = (url: string): string =>
        url.replaceAll('https://', '').replaceAll('http://', '').replaceAll('www.', '')

    const navigate = useNavigate()
    const routeChange = (id: string) => {
        const path = `/calculator/${id}`
        navigate(path)
    }

    // Sends POST request to the backend with the given report in the POST request's body
    const results = async (report: any) => {
        setSpinner(true)
        await addDelay(2000)
        try {
            // TO-DO: Extract base url into a config file
            await fetch(`${config.apiUrl}/report/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(report),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    setReportData(responseData)
                    routeChange(responseData.reportId)
                })
        } catch (error) {
            console.error(error)
        }
        setSpinner(false)
        setIsSubmitted(false)
        scrollRef.current.scrollIntoView()
    }

    // If submit button is clicked, set success to 'success'
    const [success, setSuccess] = useState('')
    const submitAttempt = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setSuccess('success')
    }

    // Sets urlError, and pageError to true respectively if there is a url, or pageViews error in errors object
    if (errors.visitors) {
        visitorError = true
    }
    if (errors.renewableEnergy) {
        energyError = true
    }

    // Update datetime of report when "Calculate again" is clicked
    useEffect(() => {
        if (dateUpdate.current != 0 && reportData.datetime != null) {
            const d = new Date(reportData.datetime)
            setReportDate(d.toString().slice(3, 15))
        }
        dateUpdate.current += 1
    }, [reportData])

    // Get date of the initial report in user readable format
    const date = new Date(props.data.datetime)
    const dateString = date.toString().slice(3, 15)

    // check the difference between current date and report generated dat to show the calculate again button
    const currentDate = new Date()
    const monthDifference =
        currentDate.getMonth() - date.getMonth() + 12 * (currentDate.getFullYear() - date.getFullYear())

    // See more in the url at result section
    const [showMore, setShowMore] = useState(false)
    const resultUrl = modifyUrl(props.data.url)

    const numberChange = (e: any) => {
        let { value } = e.target
        value = parseInt(value.toString().replace(/,/g, ''))

        if (!isNaN(value)) {
            setReportData({ ...reportData, monthlyVisitors: value })
        } else if (e.target.value.length == 0) {
            setReportData({ ...reportData, monthlyVisitors: undefined })
        }
    }

    const leftbar = (
        <AnswerContainer onSubmit={handleSubmit(RetreiveData)}>
            <TextSubContainer>
                <SubheadingWrapper>Web page views</SubheadingWrapper>
                <SentenceWrapper>How much traffic this page receives on average each month:</SentenceWrapper>
                <InputWrapper style={{ width: '100%' }}>
                    <DefaultLabel>MONTHLY VIEWS</DefaultLabel>
                    <InputBoxWrapper
                        {...register('visitors')}
                        value={reportData.monthlyVisitors?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        className={visitorError ? 'error' : success}
                        onChange={(e) => numberChange(e)}
                    />
                    <ErrorText stateType={visitorError ? 'error' : success} message={errors.visitors?.message} />
                </InputWrapper>
            </TextSubContainer>
            <SubContainer>
                <SubheadingWrapper>
                    Payload size
                    {/* <Pointer
                        direction={'down'}
                        message={
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        }
                    >
                        <img src={InfoIcon} alt='logo' />
                    </Pointer> */}
                </SubheadingWrapper>
                <SentenceWrapper>The amount of data requested when loading this page:</SentenceWrapper>
                <Subheading24 style={{ color: '#5177b8' }}>{props.data.payloadSize / 1000000} MB</Subheading24>
            </SubContainer>
            <SubContainer>
                <SubheadingWrapper>
                    Data Origin and Destination
                    {/* <Pointer
                        direction={'down'}
                        message={
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        }
                    >
                        <img src={InfoIcon} alt='logo' />
                    </Pointer> */}
                </SubheadingWrapper>
                <SentenceWrapper>
                    How far data travels when loading {modifyUrl(props.data.url)} from {props.data.destination}:
                </SentenceWrapper>
                <Subheading24 style={{ color: '#5177b8' }}>{props.data.distanceInMiles} miles</Subheading24>
            </SubContainer>
            <TextSubContainer>
                <SubheadingWrapper>Clean Energy Rating</SubheadingWrapper>
                <SentenceWrapper>
                    What percentage of the energy powering these data centers is renewable:
                </SentenceWrapper>
                {props.data.renewableEnergyPercent != null ? (
                    <>
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={100}
                            height={100}
                            value={props.data.renewableEnergyPercent}
                            percent='%'
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={() => {
                                const value = Math.round(props.data.renewableEnergyPercent)
                                const textPixels = (props.textSize * radius) / 2 || 14
                                const valueStyle = {
                                    fontSize: textPixels,
                                }
                                const percentStyle = {
                                    fontSize: textPixels * 0.6,
                                }

                                return (
                                    <tspan>
                                        <tspan className='value' style={valueStyle}>
                                            {props.data.renewableEnergyPercent}
                                            {'% '}
                                        </tspan>
                                        <tspan style={percentStyle}>{props.percent}</tspan>
                                    </tspan>
                                )
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                                fill: fillColor,
                            }}
                            waveStyle={{
                                fill: fillColor,
                            }}
                            textStyle={{
                                fill: color('#444').toString(),
                                fontFamily: 'Arial',
                            }}
                            waveTextStyle={{
                                fill: color('#fff').toString(),
                                fontFamily: 'Arial',
                            }}
                        />
                        <div></div>
                    </>
                ) : (
                    <>
                        <SentenceWrapper style={{ color: '#c5c5c5' }}>Unavailable for this region</SentenceWrapper>
                        <div></div>
                    </>
                )}
            </TextSubContainer>
            <SubContainer>
                <SubheadingWrapper>Green Hosting</SubheadingWrapper>
                <SentenceWrapper>Is your webpage using data centers powered by renewable energy?</SentenceWrapper>
                <RadioWrapper>
                    <RadioButton type='radio' {...register('greenHosting')} value='true' />
                    <label htmlFor='Yes'>Yes</label>
                    <RadioButton type='radio' {...register('greenHosting')} value='false' />
                    <label htmlFor='No'>No</label>
                </RadioWrapper>
                <Paragraph16 style={{ color: '#444444', content: 'ds' }}>
                    *If you have green hosting but it’s not showing up,&thinsp;
                    <a
                        href='https://www.thegreenwebfoundation.org/green-web-datasets/'
                        target='blank'
                        style={{ color: '#5177b8', fontFamily: 'Manrope-SemiBold' }}
                    >
                        check this database
                    </a>
                    &thinsp;to see if your website is registered.
                </Paragraph16>
            </SubContainer>
            <SubContainer style={{ alignItems: 'center' }}>
                <StyledButton onClick={submitAttempt}>Recalculate</StyledButton>
            </SubContainer>
        </AnswerContainer>
    )

    const [isHovered, setIsHovered] = useState(false)
    let showToolTip = isHovered
    const [copyLink, setCopyLink] = useState(false)
    const webcard = (
        <WebCard>
            <HeadingWrapper>Carbon results for</HeadingWrapper>
            {/* space after the resultUrl is added to seprate the url and show more/less link */}
            <AddressWrapper>
                {showMore ? `${resultUrl}  ` : `${resultUrl.substring(0, 35)}  `}
                <Recalc
                    style={{ fontSize: '16px' }}
                    onClick={() => {
                        setShowMore(!showMore)
                    }}
                >
                    {resultUrl.length < 45 ? '' : showMore ? '...Show less' : '...Show More'}
                </Recalc>
            </AddressWrapper>
            {!props.sharedReport && monthDifference >= 6 && (
                <Paragraph16>
                    Last calculated on {reportDate === '' ? dateString : reportDate}.{' '}
                    <Recalc onClick={forceRecalculate}>Calculate again</Recalc>
                </Paragraph16>
            )}
            <LogoWrapper>
                {
                    <a
                        href={
                            'https://twitter.com/intent/tweet?text=Check%20out%20this%20carbon%20footprint%20report%20from%20Digital%20Scope%20' +
                            '%3A%0D%0Ahttps%3A%2F%2Fwww.digitalscope.com%2Fcalculator%2F' +
                            props.data.reportId +
                            '%0D%0A'
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img src={twitterIcon} alt='twitter-icon' />
                    </a>
                }
                {
                    <ToolTipItem>
                        <ToolTipItem
                            onClick={() => {
                                let x = 0
                                const interval = setInterval(() => {
                                    x += 1
                                    if (x == 2) {
                                        showToolTip = false
                                        setCopyLink(false)
                                        setIsHovered(false)
                                        clearInterval(interval)
                                    }
                                }, 1000)
                                setCopyLink(true)
                                navigator.clipboard.writeText(`${config.baseUrl}/calculator/${props.data.reportId}`)
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <img src={copyLinkIcon} alt='copy-link-icon'></img>
                        </ToolTipItem>
                        {(showToolTip || copyLink) && (
                            <StyledToolTipContainer>
                                <ToolTipText>{copyLink ? 'Copied!' : 'Copy to clipboard'}</ToolTipText>
                            </StyledToolTipContainer>
                        )}
                    </ToolTipItem>
                }
            </LogoWrapper>
        </WebCard>
    )

    return (
        <>
            <div ref={scrollRef}></div>
            {spinner ? (
                <LoadingAnimation />
            ) : (
                <Container>
                    <DropdownFormContainer>
                        <FormDropdown heading={'How did we calculate this?'} content={leftbar} />
                    </DropdownFormContainer>
                    <LeftBarContainer>
                        <Subheading24>How did we calculate this?</Subheading24>
                        {leftbar}
                    </LeftBarContainer>
                    <RightContainer>
                        {webcard}
                        <RightBar data={isSubmitted ? props.data : reportData} />
                    </RightContainer>
                </Container>
            )}
        </>
    )
}

export default Result
